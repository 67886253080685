<template>
  <div>
    <h2 class="main-title mb-4">{{ $t("settings.settings") }}</h2>
    <p class="main-subtitle">
      {{ $t("settings.lastChange") }}
      {{
        new Date(User.update).toLocaleDateString() +
        ", " +
        new Date(User.update).toLocaleTimeString()
      }}
    </p>
    <h2 class="main-title mt-8">{{ $t("settings.myProfile") }}</h2>
    <v-row>
      <v-col cols="4" class="mr-4">
        <v-text-field :label="$t('settings.username')" :value="User.username" />
      </v-col>
      <v-col cols="4">
        <v-text-field :label="$t('settings.fio')" :value="User.fio" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="mr-4">
        <v-text-field :label="$t('settings.email')" :value="User.email" />
      </v-col>
    </v-row>
    <h2 class="main-title mt-8">{{ $t("settings.configNotifications") }}</h2>
    <v-row>
      <v-col cols="4" class="mr-4">
        <v-select
          :label="$t('settings.receiveNotifications')"
          :items="items"
          item-text="name"
          return-object
        ></v-select>
      </v-col>
    </v-row>
    <h2 class="main-title mt-8">{{ $t("settings.settingLanguage") }}</h2>
    <v-row>
      <v-col cols="4" class="mr-4">
        <locale />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" class="pa-0">
        <v-btn color="primary" block height="38">
          {{ $t("buttons.save") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import locale from "@/components/Locale.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    locale,
  },
  data: (vm) => ({
    items: [
      { name: vm.$t("settings.yes"), value: "yes" },
      { name: vm.$t("settings.no"), value: "no" },
    ],
    labelNot: vm.$t("settings.receiveNotifications"),
    labelUserName: vm.$t("settings.username"),
    labelFio: vm.$t("settings.fio"),
    labelEmail: vm.$t("settings.email"),
    labelRole: vm.$t("settings.role"),
  }),
  methods: {
    ...mapActions(["getBookList"]),
  },
  computed: {
    ...mapGetters(["User"]),
  },
  mounted() {
    this.getBookList({ book: "profile" });
  },
};
</script>
