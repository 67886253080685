<template>
  <div class="locale-changer">
    <v-select
      item-text="name"
      item-value="lang"
      v-model="lng"
      :items="items"
      @input="$root.$i18n.locale = lng.lang"
      return-object
      :label="$t('settings.langSelect')"
    >
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "locale",
  data: (vm) => ({
    items: [
      { name: vm.$t("settings.english"), lang: "en-US" },
      { name: vm.$t("settings.french"), lang: "fr-CH" },
    ],
  }),
  methods: {
    ...mapActions(["SAVE_LANG"]),
  },
  computed: {
    ...mapGetters(["LANG"]),
    lng: {
      get() {
        return this.LANG;
      },
      set(val) {
        this.SAVE_LANG(val);
      },
    },
  },
};
</script>
